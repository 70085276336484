<script lang="ts" setup>
import { useRouter } from 'vue-router';

import Header from '~/components/layouts/default/Header.vue';
import type { MenuItem } from '~/components/layouts/default/menu-item';
import SideMenuWrapper from '~/components/layouts/default/SideMenuWrapper.vue';

const { isMobile } = useDevice();

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const preferenceStore = usePreferenceStore();

const drawer = ref<boolean>(false);

router.afterEach(() => {
  drawer.value = false;
});

const bookingMenuItem: MenuItem = {
  label: 'Booking',
  icon: 'i-ic-event',
  name: 'booking',
  alwaysShowIcon: true,
  visible: !!preferenceStore.preference.general.enableBooking
};

const nonAuthMenu: MenuItem[] = [
  {
    label: 'Sign up',
    icon: 'i-ic-person-add',
    name: 'login-register',
    class: 'bg-company-2'
  },
  {
    label: 'Sign in',
    icon: 'i-ic-outline-login',
    name: 'login'
  },
  {
    label: 'Help',
    icon: 'i-ic-outline-help-outline',
    name: 'help',
    separator: true,
    alwaysShowIcon: true
  },
  bookingMenuItem
];

const authMenu = computed<MenuItem[]>(() => [
  bookingMenuItem,
  {
    label: 'Account',
    icon: 'i-ic-round-manage-accounts',
    name: 'account',
    alwaysShowIcon: true,
    defaultOpen: route.path.startsWith('/account'),
    children: [
      {
        label: 'Profile',
        icon: 'i-ic-round-account-box',
        name: 'account-profile'
      },
      {
        label: 'Orders',
        icon: 'i-ic-round-receipt',
        name: 'account-orders'
      },
      {
        label: 'Addresses',
        icon: 'i-ic-round-place',
        name: 'account-addresses'
      },
      {
        label: 'Cards',
        icon: 'i-ic-round-credit-card',
        name: 'account-cards'
      }
    ]
  },
  {
    label: 'Sign Out',
    icon: 'i-ic-round-logout',
    name: 'logout',
    alwaysShowIcon: true
  },
  {
    label: 'Help',
    icon: 'i-ic-outline-help-outline',
    name: 'help',
    alwaysShowIcon: true
  }
]);

const menu = computed<MenuItem[]>(() => authStore.isAuthenticated ? authMenu.value : nonAuthMenu);

const onGoHome = async (): Promise<void> => {
  if (preferenceStore.preference.template.homeUrl) {
    window.open(preferenceStore.preference.template.homeUrl, '_self');
    return;
  }

  await router.push({ name: 'index' });
};

const onShowDrawer = (): void => {
  drawer.value = true;
};
</script>

<template>
  <div class="layout-default absolute h-dvh w-svw overflow-y-auto">
    <slot
      name="header"
      :on-go-home="onGoHome"
      :on-show-drawer="onShowDrawer"
    >
      <Header
        :company-name="preferenceStore.preference.company.name"
        :logo-url="preferenceStore.preference.template.siteLogoUrl"
        :authenticated="authStore.isAuthenticated"
        :menu="menu"
        @click:drawer="onShowDrawer"
        @go-home="onGoHome"
      />
    </slot>

    <SideMenuWrapper
      v-if="isMobile"
      v-model="drawer"
      :menu="menu"
      data-test="drawer"
    />

    <slot />

    <slot name="footer" />
  </div>
</template>

<style lang="scss" scoped>
.layout-default {
  background-image:
    linear-gradient(to top,
      var(--bg-color-50),
      var(--bg-color-50)), var(--bg-image);
  background-color: var(--bg-color);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
